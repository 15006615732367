import React from 'react'
import useForm from '@hooks/useForm'
import {
  FormAddSchedule,
  initialValues,
  initialValidation,
  TFormAddSchedule
} from '@components/Form/FormAddSchedule'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from 'gatsby-material-ui-components'
import { createSchedule } from '@utils/api'
import { Grid } from '@mui/material'
import useApi from '@hooks/useApi'

const ScanSchedulerAddPage = () => {
  const api = useApi({
    apiMethod: createSchedule,
    requestOnMount: false
  })

  const form = useForm<TFormAddSchedule>({
    initialValues,
    initialValidationRules: initialValidation
  })

  return (
    <GenericFormPage
      title="Add Schedule"
      feature="schedules"
      maxWidth="md"
      breadcrumbs={[
        {
          title: 'Scheduler',
          to: '/scheduler/'
        },
        {
          title: 'Add Schedule'
        }
      ]}
      api={api}
      form={form as any}
      successMessage="Schedule successfully added."
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading}
            >
              Add Schedule
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormAddSchedule form={form as any} />
    </GenericFormPage>
  )
}

export default ScanSchedulerAddPage
